<template>
  <div>
    <div class="error-container align-self-center text-center">
      <b-img :src="ErrorIcon" class="align-self-center text-center error-icon-container" alt="error-icon" />
      <div class="error-message-container">
        <p>Sorry, you do not have permission to access this site.</p>
<p>Please <a :href="`mailto:${TeamEmail}`">contact the Financial Systems team</a> to request access. </p>
      </div>
      
    </div>
  </div>
</template>
<style scoped>
.error-container {
  text-align: center;
  max-width:calc(100% - 40%);
  max-height: 284px;
  min-height: 284px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top:3rem !important;
  background-color: rgba(216, 216, 216, 0.2);
}
 .error-message-container {
  height: 68px;
  margin-top: 31px;
} 
.error-message-container p {
  font-family: 'Open Sans';
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.02px;
  text-align: center;
  color: #4d5161;
}
.error-icon-container {
  margin-top:3.66rem;
}
.error-message-container p {
  margin-bottom: 0px;
}
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, 50%);
}
</style>
<script>
export default {
  name: "unauthorized",
  data() {
    return {
      ErrorIcon: require("@/assets/Icons/lock.svg"),
      TeamEmail:process.env.VUE_APP_SUPPORT_MAIL
    };
  },
  created() {},
  props: {
    message: null,
  },
};
</script>

